import React from "react";
import CookieConsent from "../CookieConsent/CookieConsent";

export default function Consent() {

  const onChangeCookieConsent = (choice) => {
    if(['accept', 'reject'].includes(choice)) {
    } else {
      throw new Error('cookie consent value not recognized');
    }
  }

  return (
    <div>
      <CookieConsent pplShowConsent={true} pplOnChangeChoice={onChangeCookieConsent}></CookieConsent>
    </div>
  );
}