import React from 'react';
import './styles/index.scss';


import Quote from './components/Quote/Quote';
import DesktopHeader from './components/DesktopHeader/DesktopHeader';
import Header from './components/Header/Header';
import DesktopFooter from './components/DesktopFooter/DesktopFooter';
import Footer from './components/Footer/Footer';
import Consent from './components/Consent/Consent';
import DesktopQuote from './components/DesktopQuote/DesktopQuote';

function App() {
  const queryParams = new URLSearchParams(window.location.search);
  const referralsource = queryParams.get("referralsource")
  console.log(referralsource);
  
  return (
    <div className="App">
      <DesktopHeader></DesktopHeader>
      <Header></Header>
      <Quote utmData={referralsource} ></Quote>
      <DesktopQuote utmData={referralsource} ></DesktopQuote>
      <DesktopFooter></DesktopFooter>
      <Footer></Footer>
      <Consent></Consent>    
    </div>
  );
}

export default App;
