import React from "react";
import {GiRotaryPhone} from 'react-icons/gi';

export default function DesktopHeader() {
  return (
    <div className="motor-header-dt">
      <div className="motor-header-dt__logo">
        <a id="motor-dt-header-logo-id" href="https://www.peopl.ie" rel="noopener noreferrer"target="_blank" ><img  src='./peopl-motor-logo.png' /></a>
      </div>
      <div className="motor-header-dt__menu">
        <div className="motor-header-dt__menu__menu-list">
          <a href="https://www.peopl.ie" rel="noopener noreferrer"target="_blank">Home</a>
          <a href="https://www.peopl.ie/products" rel="noopener noreferrer"target="_blank">Products</a>
          <a href="https://www.peopl.ie/about-us" rel="noopener noreferrer"target="_blank">About Us</a>
          <a href="https://www.peopl.ie/contact" rel="noopener noreferrer"target="_blank">Contact</a>
          <a href="https://www.peopl.ie/news" rel="noopener noreferrer"target="_blank">News</a>
          <a href="https://www.peopl.ie/blog" rel="noopener noreferrer"target="_blank">Blog</a>   
        </div>
        <div className="motor-header-dt__menu__call-us">
            <a href="tel:0818121121" rel="noopener noreferrer"target="_blank"><GiRotaryPhone></GiRotaryPhone> 0818 121 121</a>
        </div>
      </div>        
    </div>
  );
}