import React from "react";

export default function Footer() {
  return (
    <dv className="motor-footer">
      <div className="motor-footer__content">
        <div className="motor-footer__content__menu">
          <span>QUICK LINKS</span>
          <a href="https://www.peopl.ie/home-insurance-landing" rel="noopener noreferrer"target="_blank">Home Insurance</a>
          <a href="https://www.peopl.ie/life-insurance-landing" rel="noopener noreferrer"target="_blank">Life Insurance</a>
          <a href="https://www.peopl.ie/travel-insurance-landing" rel="noopener noreferrer"target="_blank">Travel Insurance</a>
        </div>
        <div className="motor-footer__content__menu">
          <span>OTHER LINKS</span>
          <a href="https://www.peopl.ie/about-us" rel="noopener noreferrer"target="_blank">About Us</a>
          <a href="https://www.peopl.ie/about-you" rel="noopener noreferrer"target="_blank">About You</a>
          <a href="https://www.peopl.ie/contact" rel="noopener noreferrer"target="_blank">Contact</a>
        </div>
        <div className="motor-footer__content__menu">
          <span>OTHER LINKS</span>
          <a href="https://www.peopl.ie/cookie-policy" rel="noopener noreferrer"target="_blank">Cookie Policy</a>
          <a href="https://www.peopl.ie/documents" rel="noopener noreferrer"target="_blank">Documents</a>
          <a href="https://www.peopl.ie/privacy-statement" rel="noopener noreferrer"target="_blank">Privacy Statement</a>
          <a href="https://www.peopl.ie/terms-of-business" rel="noopener noreferrer"target="_blank">Terms of Business</a>
        </div>
        <div className="motor-footer__content__logo">
          <a href="https://www.peopl.ie" rel="noopener noreferrer"target="_blank" >Peopl.</a>
        </div>      
      </div>  
    </dv>
  );
}