import React from "react";

export default function Quote(props) {
  const { REACT_APP_EQUOTE_ENV, REACT_APP_DEV_APPLIED_EQUOTE_ID } = process.env;
  const showUtmData = props.utmData !== null ? `&referralsource=${props.utmData}` : '';
   
  return (
    <div className="motor-quote">
      <div className="motor-quote__iFrame">
        <iframe title="e-quote" id="EQuoteMotorIframe" src={`https://${REACT_APP_EQUOTE_ENV}/Motor/Assumptions.aspx?bid=${REACT_APP_DEV_APPLIED_EQUOTE_ID}${showUtmData}`}></iframe>
      </div>
    </div>      
  );
}