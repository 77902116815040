import React, { useState, useEffect } from 'react'

export default function CookieConsent(props) {
  const { pplOnChangeChoice } = props;
  const [choice, setChoice] = useState('');
  const [wording, setWording] = useState('');
  const [showConsent, setShowConsent] = useState(false);

  useEffect(() => {
    setWording(props.pplWording);
  }, [props.pplWording]);

  useEffect(() => {
    setShowConsent(props.pplShowConsent);
  }, [props.pplShowConsent]);

  const onClickAgree = () => {
    setChoice('agree');
    if(pplOnChangeChoice && typeof pplOnChangeChoice === 'function'){
      pplOnChangeChoice('accept');
    }
    setShowConsent(false);
  };

  const onClickReject = () => {
    setChoice('reject');
    if(pplOnChangeChoice && typeof pplOnChangeChoice === 'function'){
      pplOnChangeChoice('reject');
    }
    setShowConsent(false);
  };

  return showConsent ? (
    <div className="motor-cookie-consent-container">
      <div className="motor-cookie-consent">
        <div className="motor-cookie-consent__wording">
          <span className="motor-mr-5">
            This website uses cookies to ensure you get the best experience on our website.
          </span>
          <a href="./peopl-cookie.pdf" target="_blank">Learn more.</a>
        </div>
        <div className="motor-cookie-consent__buttons">
          <button className="motor-cookie-consent__buttons__btn motor-cookie-consent__buttons__btn--reject" onClick={onClickReject}>Decline</button>
          <button className="motor-cookie-consent__buttons__btn motor-cookie-consent__buttons__btn--agree" onClick={onClickAgree}>Accept Cookies</button>
        </div>
      </div>
      <div className="motor-cookie-consent__mask"></div>
    </div>
  ) : null;
}
