import React, { useState } from 'react';
import { GiHamburgerMenu } from 'react-icons/gi';
import HeaderMenu from '../HeaderMenu/HeaderMenu';

export default function Header() {

  const [ showHeaderMenu, setHeaderMenu ] = useState(false);
  
  const onCloseHeaderMenu = () => {
    setHeaderMenu(false);
  }

  const showHideMenu = () => {
    if(!showHeaderMenu) setHeaderMenu(true);     
  }

  return (
    <div className='motor-header'>
      <div className="motor-header__logo">
        <a id="motor-header-logo-id" href="https://www.peopl.ie" rel="noopener noreferrer"target="_blank" ><img  src='./peopl-motor-logo.png' /></a>
      </div>
      <div className="motor-header__menu">
        <GiHamburgerMenu onClick={showHideMenu}></GiHamburgerMenu>       
      </div>
      <div className='motor-header-menu'>
        <HeaderMenu onClickClose={onCloseHeaderMenu} showHeaderMenu={showHeaderMenu}></HeaderMenu>
      </div>           
    </div>
    
  );
}