import React, { useEffect, useState } from 'react';
import { CgCloseR } from 'react-icons/cg';
import {GiRotaryPhone} from 'react-icons/gi';

export default function HeaderMenu(props) {
  const { onClickClose } = props;
  const [ headerMenuShow, setHeaderMenuShow ] = useState(props.showHeaderMenu);
  
  useEffect(() => {
    if(props.showHeaderMenu) setHeaderMenuShow(props.showHeaderMenu)
  }, [props.showHeaderMenu]);

  const onClickOK = () => {
    setHeaderMenuShow(false);
    if(onClickClose && typeof onClickClose === 'function') onClickClose();
  }

  return (
    <div className={`motor-header-menu__content ${headerMenuShow ? 'show' : 'hide'}`}>
      <div className="motor-header-menu__icon">
        <CgCloseR onClick={onClickOK} className="motor-header-menu__icon__style" ></CgCloseR>
      </div>
      <div className="motor-header-menu__menu">
        <a href="https://www.peopl.ie" rel="noopener noreferrer"target="_blank">Home</a> 
        <a href="https://www.peopl.ie/products" rel="noopener noreferrer"target="_blank">Products</a> 
        <a href="https://www.peopl.ie/blog" rel="noopener noreferrer"target="_blank">Blog</a>
        <a href="https://www.peopl.ie/news" rel="noopener noreferrer"target="_blank">News</a>                                      
        <a href="https://www.peopl.ie/contact" rel="noopener noreferrer"target="_blank">Contact</a>       
        <a href="https://www.peopl.ie/about-us" rel="noopener noreferrer"target="_blank">About Us</a>           
      </div>
      <div className='motor-header-menu__call-us'>
        <a href="tel:0818121121" rel="noopener noreferrer"target="_blank"><GiRotaryPhone></GiRotaryPhone> 0818 121 121</a>
      </div>
    </div>
  );
}